import React from 'react';

import { graphql } from 'gatsby';

import DefaultLayout from '../layouts';
import ContentSection from '../layouts/content-section';
// Import PostSummary query fragment from NewsItem
// eslint-disable-next-line
import NewsItem from '../components/news-item';
import PostHeader from '../components/post-header';
import WordpressContent from '../components/wordpress-content';
import Metadata from '../components/metadata';

const PostTemplate = ({ data, location, pageContext }) => {
  const currentPost = data.wpPost;

  return (
    <DefaultLayout>
      <Metadata post={currentPost} location={location} />

      <ContentSection>
        <PostHeader post={currentPost} listingPage={pageContext.listingPage} />
        <WordpressContent>{currentPost.content}</WordpressContent>
      </ContentSection>
    </DefaultLayout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      ...PostSummary
      content
    }
  }
`;
