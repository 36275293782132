import React from 'react';
import { Link } from 'gatsby';

import * as headerStyles from './post-header.module.css';
import PostInfo from './post-info';
import { pathForPage } from './pagination';

const PostHeader = ({ post, listingPage }) => (
  <header className={headerStyles.postHeader}>
    <Link to={pathForPage('/uutiset', listingPage)}>« Takaisin</Link>
    <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1>
    <PostInfo date={post.date} />
  </header>
);

export default PostHeader;
